<template>
  <div class="wap-file-upload" v-loading="loading">
    <div class="file-list">
      <div class="file-item" v-for="(file, index) in fileDatas" :key="index">
        <div class="file-name" @click="preview(file, index)">{{file.name}}</div>
        <i class="icon-shanchu iconfont" @click="del(file)"></i>
        <!-- <div class="icon" v-if="getLab(file)">{{getLab(file)}}</div> -->
      </div>
    </div>
    <div class="add-btn" @click="dataFileParm.isDoc ? (status.action = true) : $refs.upload.click()">+ 上传文件</div>
    <input type="file" style="display: none;" @change="startUpload" ref="upload">
    <van-popup v-model="status.doc" round position="bottom" :close-on-click-overlay="false">
      <scan-doc
        :title="dataFileParm.docName || '扫描件'"
        :aspect-ratio="dataFileParm.docAspectRatio || false"
        @confirm="scanConfirm"
        @close="status.doc = false"
      />
    </van-popup>

    <van-overlay :show="status.loading">
      <div class="wrapper" @click.stop style="display: flex; align-items: center; justify-content: center; height: 100%;">
        <van-loading size="24px" color="#fff" vertical>加载中...</van-loading>
      </div>
    </van-overlay>

    <van-action-sheet cancel-text="取消" v-model="status.action" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import ScanDoc from '@/components/scan/doc'

import { ImagePreview } from 'vant'

let filesCache = {}

async function getImage (fileDatas) {
  let res = []
  for (const item of fileDatas) {
    if (!filesCache[item.id]) {
      try {
        const file = await fileRequest.download({
          id: item.id
        })
        if (file) {
          const img = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
          })
          filesCache[item.id] = img
        } else {
          filesCache[item.id] = null
        }
      } catch (error) {
        filesCache[item.id] = false
      }
    }
    res.push(filesCache[item.id])
  }
  return res
}

export default {
  name: 'wap_file_list',
  components: { ScanDoc },
  props: {
    dataFileParm: {
      type: Object
    }
    // allData: {
    //   type: Object,
    //   default: () => {
    //     return null
    //   }
    // }
  },
  data () {
    return {
      fileDatas: [],
      status: {
        doc: false,
        loading: false,
        action: false,
      },
      files: [],
      loading: false,
      actions: [
        {name: '相册上传', subname: '从相册中选择图片'},
        {name: '拍照扫描', subname: '使用手机自带的摄像头扫描'}
      ]
    }
  },
  created () {
    this.loadFile()
  },
  methods: {
    onSelect ({name}) {
      this.status.action = false
      if (name === '相册上传') {
        this.$refs.upload.click()
      } else if (name === '拍照扫描') {
        this.status.doc = true
      }
    },
    async preview (file, i) {
      try {
        this.status.loading = true
        const res = await getImage(this.fileDatas)
        this.status.loading = false
        if (res.length) {
          ImagePreview({
            images: res,
            startPosition: i
          })
        } else {
          throw new Error('文件不存在')
        }
      } catch (error) {
        this.$notify({type: 'danger', message: error.message});
      }
    },
    async scanConfirm (blob) {
      this.status.doc = false
      if (blob) {
        const file = new File([blob], this.dataFileParm.docName + '.' + (new Date()).getTime() + '.png')
        this.loading = true
        let parm = new FormData()
        parm.append('file', file)
        let data = await fileRequest.upload(parm)
        this.fileDatas.push(data)
        await this.$emit('addFile', data)
        this.loading = false
      }
    },
    // getLab (file) {
    //   if (file.fType === 'wait') {
    //     return '提'
    //   }
    //   if (file.fType === 'draft') {
    //     return '存'
    //   }
    // },
    async del (item) {
      this.loading = true
      // await fileRequest.del({id: item.id})
      this.fileDatas = this.fileDatas.filter(v => v.id !== item.id)
      await this.$emit('delFile', item)
      this.loading = false
      // this.$notice.info({
      //   title: '系统提示',
      //   desc: '文件已删除'
      // })
    },
    async loadFile () {
      this.loading = true
      if (this.dataFileParm.fileIds && this.dataFileParm.fileIds.length > 0) {
        this.fileDatas = await fileRequest.getFileData({
          ids: this.dataFileParm.fileIds.join(',')
        })
      } else {
        this.fileDatas = []
      }
      this.loading = false
    },
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        this.loading = true
        let file = files[0]
        let parm = new FormData()
        parm.append('file', file)
        let data = await fileRequest.upload(parm)
        this.fileDatas.push(data)
        await this.$emit('addFile', data)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scope>
.wap-file-upload{
  margin-top: 10px;
  .file-item {
    height: 40px;
    width: calc(100% - 15px);
    margin: 0 0px 0 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .file-name {
      color: #323233;
      font-size: 14px;
      max-width: calc(100% - 80px);
      white-space:nowrap;
      overflow: hidden;
      text-overflow:ellipsis;
    }
  }
  .icon {
  line-height: 18px;
  margin-right: 10px;
  min-width: 18px;
  font-size: 14px;
  text-align: center;
  background: #F4628F;
  color: #fff;
  height: 18px;
  width: 18px;
  border-radius: 100%;
}
  .add-btn {
    height: 32px;
    background: #B2B2B2;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
}
</style>
